import React, { Component } from 'react'
import '../styles/transport.scss'
import history from "../assets/images/imgs/history.png";
import place from "../assets/images/imgs/place.png";
import keyi from "../assets/images/imgs/keyi.png";
import jinzhi from "../assets/images/imgs/jinzhi.png";
import progress from "../assets/images/imgs/progress.png";

import { DownOutlined, PhoneOutlined, HistoryOutlined, RocketOutlined, PayCircleOutlined, SafetyCertificateOutlined, EyeOutlined } from '@ant-design/icons';
const list = [{
  img: place,
  name: '主要服务于个人、代购、微商'
}, {
  img: keyi,
  name: '主要服务于各大电商平台、网店主'
}, {
  img: jinzhi,
  name: '主要服务于个人、海外留学生'
}]
export default class Transport extends Component {
  state: { advantageList: any[] }
  constructor(props: any) {
    super(props)
    this.state = {
      advantageList: [
        {
          icon: <PhoneOutlined />,
          title: '服务贴心',
          text1: '7/12小时的专业在线客服',
          text2: '让你有不一样的海淘体验'
        },
        {
          icon: <HistoryOutlined />,
          title: '时效稳定',
          text1: '多口岸多渠道',
          text2: '确保货物按时抵达'
        }, {
          icon: <RocketOutlined />,
          title: '清关迅速',
          text1: '专人负责清关流程',
          text2: '让货物在最短的时间内完成清关'
        }, {
          icon: <PayCircleOutlined />,
          title: '价格低廉',
          text1: '实时收费，价格透明',
          text2: '实现国内价格享受国际服务'
        }, {
          icon: <SafetyCertificateOutlined />,
          title: '安全放心',
          text1: '货物出现问题时',
          text2: '可先行赔付，不用担心损失成本'
        }, {
          icon: <EyeOutlined />,
          title: '公开透明',
          text1: '全程物流跟踪体系',
          text2: '专业转运一单到底'
        }
      ]
    }
  }
  render() {
    return (
      <div className='transport'>
        <div className='model'>
          <div className='title'>
            服务背景
          </div>
          <div className='content'>
            <img src={history} alt="" />
            <div className='desc'>
              &#x3000;&#x3000;随着互联网技术的发展和普及，近年来，中国与世界各国之间跨境电子商务方面的合作发展达到了一个新高度。“海淘”逐渐走进中国人民家庭当中的同时，中国商品也出现在各国人民的生活之中，国际运转由诞生。速邮达作为一家资深的跨国物流公司，特开设中国进出口全球的集运、转运业务。借助自身特色，搭建一个服务于全球人民的集运、转运平台。速邮达本着一贯优越操作的态度，高效的运输品质为每一位客户提供优质的服务。提供一键下单、个人物品直邮、行李运输、一般贸易、小件直邮、境外转运等服务平台。用细节流程、精准运输作为我们的服务宗旨，努力构建中国与世界各国之间的运输桥梁，保证客户包裹物品畅通无阻！
            </div>
          </div>
        </div>
        <div className='advantage'>
          <div className='advantage-model'>
            <div className='title'>
              服务优势
            </div>
            <div className='advantage-list'>
              {this.state.advantageList.map((item, index) => (
                <div key={index} className='item'>
                  <div className='icon'>
                    {item.icon}
                  </div>
                  <div className='item-title'>
                    {item.title}
                  </div>
                  <div className='text1'>
                    {item.text1}
                  </div>
                  <div className='text2'>
                    {item.text2}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='model'>
          <div className='title'>
            服务对象
          </div>
          <div className='dx'>
            {list.map((item, index) => (
              <div key={index} className='content-item'>
                <img src={item.img} alt="" />
                <div className='content-name'>{item.name}</div>
              </div>
            ))}
          </div>
        </div>
        <div className='model'>
          <div className='title'>
            转运流程
          </div>
          <div className='lc' style={{ marginTop: '50px' }}>
            <img src={progress} alt="" />
          </div>
        </div>
      </div >
    )
  }
}
