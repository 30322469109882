import React, { Component } from 'react'
import '../styles/about.scss'
import cont1 from "../assets/images/imgs/cont1.png";
import cont2 from "../assets/images/imgs/cont2.png";
import cont3 from "../assets/images/imgs/cont3.png";
import cont4 from "../assets/images/imgs/cont4.png";
import ploat from "../assets/images/imgs/ploat.png";

const imgList = [cont1, cont2, cont3, cont4]
const historyList = [
  "2013年：速邮达正式成立，并开展国际物流揽件业务，开通五大通关关口，建立两大保税仓。",
  "2014年：跨境直邮一单到底业务正式开通，与唯品会达成项目合作。",
  "2015年：飞牛网、蜜淘网等电商平台与我公司成功对接，成为合作伙伴。",
  "2016年：成为青岛跨境电商产业园战略合作伙伴",
  "2017年：与顺丰旗下丰趣海淘签订合作协议，为丰趣海淘提供国际仓储、中转及国际干线运输服务。",
  "2018年：成为威海综合保税区重点合作企业，并被苏宁指定为中韩进出口货物的唯一合作伙伴。",
]
export default class Transport extends Component {
  render() {
    return (
      <div className='transport'>
        <div className='model'>
          <div className='title'>
            公司背景
          </div>
          <div className='descs'>
            速邮达成立于2013年，是一家以国际物流为核心业务，集海外仓储、跨境电商供应链、国际物流解决方案、系统开发为一体的国际贸易综合服务提供商。经过五年的发展，公司在干线运输领域，先后与山东航空、天津航空等多家航空公司签订一系列战略合作协议，并且率先国际货运包机航线。在跨境电商领域，速邮达成功在海外建仓，为跨境电商平台提供一单到底的海外仓储及跨境直邮业务，先后与唯品会、飞牛网、大韩家、蜜淘网、韩国CJ食品、嗨韩网、丰趣海淘等知名电商平台建立合作关系。
          </div>
        </div>
        <div className='history'>
          <img src={ploat} alt="" />
          <div className='history-content'>
            <div className='history-title'>公司大事记</div>
            <div className='history-title'>Company memorabilia</div>
            <div className='history-list'>
              {historyList.map((item, index) => (
                <div className='history-item' key={index}>{item}</div>
              ))}
            </div>
          </div>
        </div>
        <div className='model' style={{ paddingBottom: "0" }}>
          <div className='title'>
            公司风采
          </div>
          <div className='descs'>
            速邮达成立于2013年，是中国较早成立的国际跨境物流公司之一。经过多年的诚信经营，速邮达已与世界多个国家、地区建立了广泛的业务合作关系，为中国物流业贡献自己的一份力量。
          </div>
          <div className='descs'>
            速邮达涵盖个人物品直邮、跨境电商直邮、小件直邮、一般贸易、行李运输等业务。为海外商家和海外电商客户提供全方位、一体化的国际快递物流服务。为多个知名商家提供了全程的供应链仓储物流服务，到目前为止已经服务了成千上万个海淘客户。
          </div>
          <div className='img-list'>
            {imgList.map((item, index) => (
              <img key={index} src={item} alt="" />
            ))}
          </div>
        </div>
      </div>
    )
  }
}
