import React, { Component } from 'react'
import '../styles/transport.scss'
import liucheng from "../assets/images/imgs/liucheng.png";
import big from "../assets/images/imgs/big.png";
import mar from "../assets/images/imgs/mar.png";
import team from "../assets/images/imgs/team.png";
import yunshu from "../assets/images/imgs/yunshu.png";

import { DownOutlined, PhoneOutlined, HistoryOutlined, RocketOutlined, PayCircleOutlined, SafetyCertificateOutlined, EyeOutlined } from '@ant-design/icons';
const list = [{
  img: big,
  name: '系统化操作',
  desc: "自行开发了GOS全球货运系统。把客户订单、物流监控，海关申报系统联系在一起。提供完整的系统化解决方案，与电商平台完美对接。"
}, {
  img: mar,
  name: '多样化运输',
  desc: "与山东航空、天津航空、仁川航空等13家航空公司及威东航运、韩国大仁轮渡、胶东国际海运等6家船运公司签署战略合作，同时，还提供青岛、济南、威海、烟台、天津、昆明、武汉、哈尔滨、广州等口岸的清关服务，为客户提供更多选择，并最大程度节省客户的成本。"
}, {
  img: team,
  name: '仓储化管理',
  desc: "提供一站式仓储配送服务，帮助中国客户实现海外仓储集包服务，降低物流运营成本，进行实时库存管理与监测，缩短发货时间，提高顾客满意度。"
}, {
  img: yunshu,
  name: '全方位服务',
  desc: "韵达在中国拥有3300家门店，50000多个窗口，全网160000名员工，完美解决最后一公里。此外，还配备专门客服，进行7*12小时客服服务货物出现问题，先行赔付。完美做到细化转运流程，精准运输，诚信感恩，客户第一的服务理念"
}]
const fwList = [{
  name: '正规合法操作',
  desc: '海关全程监管，正规依法操作'
}, {
  name: '简单快捷清关',
  desc: '专人负责清关流程，让货物在最短的时间内完成清关'
}, {
  name: '多种商品支持',
  desc: '适合各种类型进口商品'
}, {
  name: '高效完善售后',
  desc: '配备专门客服，进行7*12小时客服服务'
}
]
export default class Transport extends Component {
  state: { advantageList: any[] }
  constructor(props: any) {
    super(props)
    this.state = {
      advantageList: [
        {
          icon: <PhoneOutlined />,
          title: '服务贴心',
          text1: '7/12小时的专业在线客服',
          text2: '让你有不一样的海淘体验'
        },
        {
          icon: <HistoryOutlined />,
          title: '时效稳定',
          text1: '多口岸多渠道',
          text2: '确保货物按时抵达'
        }, {
          icon: <RocketOutlined />,
          title: '清关迅速',
          text1: '专人负责清关流程',
          text2: '让货物在最短的时间内完成清关'
        }, {
          icon: <PayCircleOutlined />,
          title: '价格低廉',
          text1: '实时收费，价格透明',
          text2: '实现国内价格享受国际服务'
        }, {
          icon: <SafetyCertificateOutlined />,
          title: '安全放心',
          text1: '货物出现问题时',
          text2: '可先行赔付，不用担心损失成本'
        }, {
          icon: <EyeOutlined />,
          title: '公开透明',
          text1: '全程物流跟踪体系',
          text2: '专业转运一单到底'
        }
      ]
    }
  }
  render() {
    return (
      <div className='transport'>
        <div className='model'>
          <div className='title'>
            云仓介绍
          </div>
          <div className='desc'>
            区别于传统保税单仓发货模式，通过云仓系统，全国多个保税仓一步对接，多个清关口岸一站联网，实现订单智能调拨，
            就近清关发货，让客户在资源共享、配送灵活性、物流成本、物流时效，用户体验等方面更具优势。
          </div>
          <div className='liucheng'>
            <img src={liucheng} alt="" />
          </div>
        </div>
        <div className='model'>
          <div className='title'>
            业务优势
          </div>
          <div className='desc'>
            我们是一家资深的国际物流运输公司，拥有多年的物流运输经验。完备的全球货运系统让客户可以享受到一单到底，全程跟踪货物信息的优质体验，更有多个海外仓储为客户解决发货时间长，过程繁琐等问题。
          </div>
          <div className='dx'>
            {list.map((item, index) => (
              <div key={index} className='content-item'>
                <img src={item.img} alt="" />
                <div className='content-name'>{item.name}</div>
                <div className='content-desc'>{item.desc}</div>
              </div>
            ))}
          </div>

        </div>
        <div className='fwys'>
          <div className='fw'>
            <div className='fw-title'>
              服务优势
            </div>
            <div className='fw-list'>
              {fwList.map((item, index) => (
                <div key={index} className='fw-item'>
                  <div className='roll'></div>
                  <div className='fw-name'>{item.name}</div>
                  <div className='fw-desc'>{item.desc}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
