import Home from 'components/Home';
// import { ReactComponent as Logo } from 'assets/favicon.svg';
import React, { useState } from "react";
import { HashRouter, Route, NavLink, Switch, BrowserRouter, Link, Redirect } from 'react-router-dom'
import { Dropdown, Menu, Space, BackTop, message } from "antd";
import "antd/dist/antd.css";
import './styles/style.scss'
import './styles/common.scss'
import { DownOutlined, SearchOutlined, SwapRightOutlined } from '@ant-design/icons';
import img_mian from './assets/images/icons/mian.jpg'
import img_phone from './assets/images/icons/phone.jpg'
import banner from './assets/images/imgs/banner.jpg'
import logo from './assets/images/imgs/logo.png'
import Register from "components/Register";
// import Header from 'components/Header';
import { createBrowserHistory } from "history";
import Transport from "components/transport";
import Warehouse from "components/warehouse";
import Directimport from "components/directimport";
import Expressimport from "components/expressimport";
import About from "components/about";
import axios from 'axios'

const bannerStyle = {
  width: "100%",
  height: "650px",
  backgroundImage: `url(${banner})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%'
}
const history = createBrowserHistory()
const menu = (
  <Menu
    items={[
      {
        key: '1',
        label: (
          <Link className='li' to="/directimport" onClick={() => { sessionStorage.setItem('reactNav', '5'); window.location.reload() }}>出口服务</Link>
        ),
      },
      {
        key: '2',
        label: (
          <Link className='li' to="/expressimport" onClick={() => { sessionStorage.setItem('reactNav', '6'); window.location.reload() }}>进口服务</Link>

        ),
      },
    ]}
  />
);
const menu2 = (
  <Menu
    items={[
      {
        key: '1',
        label: (
          <a target="_blank" rel="noopener noreferrer" href="http://docs.suyoda.cn/">
            接口文档
          </a>),
      },
      {
        key: '2',
        label: (
          <a target="_blank" rel="noopener noreferrer" href="https://www.yuque.com/books/share/e91157fc-c7b3-4457-be77-108363779c5f/ps5sma">
            帮助文档
          </a>
        ),
      },
    ]}
  />
);
const contact = [{
  name: '客服电话：400-6061-938',
}, {
  name: '业务电话：400-6061-938',
}, {
  name: '邮 箱：2881973148@qq.com',
}, {
  name: '地 址：青岛市城阳区棘洪滩街道青大工业园春海路4号',
}]
const about = [{
  name: '公司简介',
}, {
  name: '媒体报道',
}, {
  name: '帮助中心',
}, {
  name: '客户案例',
}]
const link = [{
  name: '苏宁易购',
  link: 'https://www.suning.com/'
}, {
  name: '丰趣海淘',
  link: 'http://www.fengqu.com/'
}, {
  name: '蘑菇街',
  link: 'https://www.mogu.com/'
}, {
  name: '折疯了海淘',
  link: 'http://www.zhefengle.com/'
}, {
  name: 'hahamall',
  link: 'https://kmall.kaola.com/app/969434?shareOs=iOS&datid=__da_230bb323_5691a97408c33c80&_noheader=true&_toggleTitle=true&openNewPage=true&_fullscreen=true&cdnBaseUrl=http%3A%2F%2Fm.kaolacdn.com&isTestEnvironment=false'
}, {
  name: 'interpark',
  link: 'http://www.globalinterpark.com/main/main'
}, {
  name: '韵达国际部官网',
  link: 'http://www.udalogistic.com/index'
}, {
  name: '快递100',
  link: 'https://api.kuaidi100.com/home'
}]
export default class App extends React.Component {
  state: { rate: string, active: number | string }
  constructor(props: any) {
    super(props)
    this.state = {
      active: sessionStorage.getItem('reactNav') || 1,
      rate: ""
    }
    this.getRate()
  }
  getRate() {
    axios({
      method: 'post',
      url: 'https://www.suyoda.cn/api/get_route/getRate',
      // url: 'api/get_route/getRate',

      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', 'Accept': 'application/json, text/javascript, */*; q=0.01' }
    }).then(res => {
      this.setState({
        rate: res.data.data[0].title
      })

    }).catch(err => {
      console.log(err);
      message.error('查询失败', 2);
    })
  }
  static getDerivedStateFromProps() {
    if (!sessionStorage.getItem('reactNav')) {
      sessionStorage.setItem('reactNav', '1')
    }
  }
  render() {
  return (
    <HashRouter>
      <BackTop />
      <Switch>
        <Route path="/Register" component={Register}></Route>
        <Route path="/">
          <div className='nav-header'>
            <div className="rate">
              <div>
                {this.state.rate}
              </div>
              <div style={{
                fontSize: '12px',
                width: '620px',
                marginLeft: '20px',
              }
              }>
                <div>
                  系统汇率每周变更一次，原则上于每周一更换最新汇率，具体以系统公布为准（节假日或有顺延）。
                </div>
                <div>
                  人民币报价支付韩币和韩币报价支付人民币的情况，执行不同的外汇买卖汇率。
                </div>
                <div>
                  其中韩服的结算执行韩国单独规定，不在此规定范围之内。
                </div>
              </div>
            </div>


            <div className='header-main'>
              <img style={{ position: 'relative', top: '-2px' }} src={img_mian} alt="" />
              <span onClick={() => {
                window.location.href = 'https://juc.suyoda.cn/vue/#/login'
              }} style={{ cursor: 'pointer' }} className='mr-20 ml-1'>登录</span>
              <span style={{ position: 'relative', top: '-1px' }} className='mr-20'>|</span>
              <span onClick={() => {
                window.location.href = 'https://juc.suyoda.cn/vue/#/register'
              }} style={{ cursor: 'pointer', color: '#fff' }} className='mr-20'>注册</span>
              <span style={{ position: 'relative', top: '-1px' }} className='mr-20'>|</span>
              <img src={img_phone} style={{ width: '25px', height: '27px', position: 'relative', top: '1px' }} alt="" />
              <span>400-606-1938</span>
            </div>
          </div>
          <div className='banner' style={bannerStyle}>
            <div className='banner-main'>
              <img style={{ cursor: 'pointer' }} onClick={() => {
                this.setState({
                  active: 1
                }); sessionStorage.setItem('reactNav', '1')
              }} className='logo' src={logo} alt="" />
              <ul className='menu-list'>
                <Link className='li' to="/home" onClick={() => {
                  this.setState({
                    active: 1
                  }); sessionStorage.setItem('reactNav', '1')
                }}>首页 {Number(sessionStorage.getItem('reactNav')) == 1 ? <div className='line'></div> : ''}</Link>
                <Link className='li' to="/transport" onClick={() => {
                  this.setState({
                    active: 2
                  }); sessionStorage.setItem('reactNav', '2')
                }}>转运业务 {Number(sessionStorage.getItem('reactNav')) == 2 ? <div className='line'></div> : ''}</Link>
                <Link className='li' to="/warehouse" onClick={() => {
                  this.setState({
                    active: 3
                  }); sessionStorage.setItem('reactNav', '3')
                }}>保税云仓 {Number(sessionStorage.getItem('reactNav')) == 3 ? <div className='line'></div> : ''}</Link>
                <li>
                  <Dropdown overlay={menu} arrow={true}>
                    <Link className='li' style={{ margin: '0' }} to="/directimport" onClick={() => {
                      this.setState({
                        active: 5
                      }); sessionStorage.setItem('reactNav', '5')
                    }}>
                      <Space>
                        进口出口
                        <DownOutlined style={{ fontSize: '14px', position: 'relative', top: '-5px' }} />
                      </Space>
                      {Number(sessionStorage.getItem('reactNav')) == 5 || Number(sessionStorage.getItem('reactNav')) == 6 ? <div className='line'></div> : ''}
                    </Link>
                  </Dropdown>
                </li>
                <li>
                  <Dropdown overlay={menu2} arrow={true}>
                    <button onClick={e => e.preventDefault()}>
                      <Space>
                        系统相关
                        <DownOutlined style={{ fontSize: '14px', position: 'relative', top: '-5px' }} />
                      </Space>
                    </button>
                  </Dropdown>
                </li>
                <Link className='li' to="/about" onClick={() => {
                  this.setState({
                    active: 4
                  }); sessionStorage.setItem('reactNav', '4')
                }}>关于我们<SearchOutlined style={{ fontSize: '18px', position: 'relative', top: '-4px', left: '10px' }} /> {Number(sessionStorage.getItem('reactNav')) == 4 ? <div className='line'></div> : ''}</Link>
              </ul>
            </div>
            <div className='banner-main mt-40'>
              <div className="banner-title">一站式的物流解决方案平台</div>
            </div>
            <div className='banner-main'>
              <div className="banner-name">行业内高水平的优化团队&#x3000;I&#x3000; 独立研发的数据分析系统&#x3000;I&#x3000;提供全方位的服务</div>
            </div>
            {/* <div className="banner-main">
              <div style={{ cursor: 'pointer' }} onClick={() => {
                this.setState({
                  active: 4
                }); sessionStorage.setItem('reactNav', '4')
              }} className="detial">
                <span>Details</span>
                <SwapRightOutlined style={{ fontSize: '30px' }} />
              </div>
            </div> */}
          </div >
          <div>
            <Route path="/home" component={Home}></Route>
            <Route path="/transport" component={Transport}></Route>
            <Route path="/warehouse" component={Warehouse}></Route>
            <Route path="/directimport" component={Directimport}></Route>
            <Route path="/expressimport" component={Expressimport}></Route>
            <Route path="/about" component={About}></Route>
            <Redirect to={['/home', '/transport', '/warehouse', '/about', 'directimport', 'expressimport'][Number(sessionStorage.getItem('reactNav')) - 1]}></Redirect>
          </div>
          <div className='footer'>
            <div className='footer-main'>
              <div className='footer-top'>
                <div className='suyoda'>
                  <img src={logo} alt="" />
                  <div className='tel'>400-606-1938</div>
                  <div className='time'>周一至周五:8:30-17:30</div>
                  <div className='time'>周六:8:00-12:00 周日休息</div>
                </div>
                <div className='yqlj'>
                  <div className='title'>
                    友情链接
                  </div>
                  <div className='links'>
                    {link.map((item, index) => (
                      <div  key={index} className='links-list'>
                        <a style={{ color: '#fff' }} target='_blank' href={item.link}>{item.name}</a>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className='yqlj'>
                  <div className='title'>
                    关于我们
                  </div>
                  <div className='about'>
                    {about.map((item, index) => (
                      <div key={index} className='about-list'>
                        <a style={{ color: '#fff' }} target='_blank' >{item.name}</a>
                      </div>
                    ))}
                  </div>
                </div> */}
                <div className='yqlj'>
                  <div className='title'>
                    联系我们
                  </div>
                  <div className='about'>
                    {contact.map((item, index) => (
                      <div key={index} className='about-list'>
                        <a style={{ color: '#fff' }} target='_blank'>{item.name}</a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='footer-bottom'>
                <a style={{ color: '#fff' }} target='_blank' href='http://beian.miit.gov.cn/'>
                  鲁ICP备17015604号-5
                </a>
                <div>
                  © 2018 版权所有 青岛润亨丰国际贸易有限公司,All Right Reserved
                </div>
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </HashRouter >
  );
}
}


// export default App;
