import React, { Component } from 'react'
import '../styles/directimport.scss'
import banner1 from "../assets/images/imgs/banner1.png";
import banner2 from "../assets/images/imgs/banner2.png";
import banner3 from "../assets/images/imgs/banner3.png";

import img1 from "../assets/images/imgs/img1.png";
import img2 from "../assets/images/imgs/img2.png";
import img3 from "../assets/images/imgs/img3.png";
import img4 from "../assets/images/imgs/img4.png";
import img6 from "../assets/images/imgs/img6.png";

import moshi from "../assets/images/imgs/moshi.png";
import liucheng from "../assets/images/imgs/liucheng.png";
export default class Transport extends Component {
  render() {
    return (
      <div className='transport'>
        <div className='model'>
          <div className='title'>
            出口服务
          </div>
          <div className='content'>
            <div className='content-item'>
              <div className='content-title'>
                模式介绍
              </div>
              <div className='content-desc'>
                电商在海关部门完成企业和商品备案后，由速邮达负责与海关对接并运输。此外，因速邮达与韩国邮政达成战略合作关系，中国客户可通过速邮达在中国境内的5万余家门店向韩国收件人或单位发送货物，由韩国邮政进行配送
              </div>
            </div>
            <div className='content-item'>
              <div className='content-title'>
                服务模式
              </div>
              <div className='content-desc'>
                <img src={moshi} alt="" />
                为出口企业及个人提供高效率、低成本的国内外仓储、国际运输、清关、派送一化服务
              </div>
            </div>
            <div className='content-item'>
              <div className='content-title'>
                服务对象
              </div>
              <div className='content-desc'>
                1、中国境内的出口企业及境外独立电商平台企业
                <div>2、中国境内个人发件人及境外个人收件人
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='advantages'>
          <div className='advantage-model'>
            <div className='title'>
              服务流程
            </div>
            <div className='advantage-desc'>
              合理化的服务流程，更加方便快捷，服务用户。
            </div>
            <div className='advantage-list'>
              <img src={liucheng} alt="" />
            </div>
          </div>
        </div>

        <div className='hxys'>
          <div className='title'>
            核心优势
          </div>
          <div className='hxys-content'>
            <div className='hxys-desc'>
              <div className='desc-title'>
                全球范围供应链的整合和优化
              </div>
              <div className='hxys-list'>
                <div className='hxys-item'>
                  <img src={img1} alt="" />
                  <div className='item-content'>
                    <div className='item-title'>
                      完善的移动端建设
                    </div>
                    <div className='item-desc'>
                      顺应电商移动化发展趋势，自主研发相应的全球货运系统，满足客户需求的同时，也为用户解决操作繁琐的难题。
                    </div>
                  </div>
                </div>
                <div className='hxys-item'>
                  <img src={img2} alt="" />
                  <div className='item-content'>
                    <div className='item-title'>
                      海外仓建设推进
                    </div>
                    <div className='item-desc'>
                      不仅能让海外进口品牌在国内得到孵化，也能满足国人对个性化的追求，为国人打造了一个囊括全球精品的海外直邮平台。
                    </div>
                  </div>
                </div>
                <div className='hxys-item'>
                  <img src={img2} alt="" />
                  <div className='item-content'>
                    <div className='item-title'>
                      空海多式联运
                    </div>
                    <div className='item-desc'>
                      以时效快，价格便宜，服务内容多样化，发货流程简捷便利的特点为您提供全程一站式服务，把国际货运当做国内货运操作，将空运和海运相结合。货物全程在线跟踪，随时了解货物动态
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='hxys-img'>
              <img src={banner1} alt="" />
            </div>
          </div>
          <div className='hxys-content' style={{ borderTop: '1px solid rgb(218, 218, 218)', paddingTop: "50px" }}>
            <div className='hxys-img' style={{ marginRight: "20px" }}>
              <img src={banner2} alt="" />
            </div>
            <div className='hxys-desc'>
              <div className='desc-title'>
                大系统，大能量
              </div>
              <div className='hxys-list'>
                <div className='hxys-item'>
                  <img src={img3} alt="" />
                  <div className='item-content'>
                    <div className='item-title'>
                      便捷的一单到底
                    </div>
                    <div className='item-desc'>
                      把客户订单、物流监控，海关申报系统联系在一起。
                    </div>
                  </div>
                </div>
                <div className='hxys-item'>
                  <img src={img4} alt="" />
                  <div className='item-content'>
                    <div className='item-title'>
                      无缝隙的完美对接
                    </div>
                    <div className='item-desc'>
                      提供完整的系统化解决方案，与电商平台完美对接。
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className='hxys-content' style={{ borderTop: '1px solid rgb(218, 218, 218)', paddingTop: "50px" }}>
            <div className='hxys-desc'>
              <div className='desc-title'>
                立足客户需求的金牌服务
              </div>
              <div className='hxys-list'>
                <div className='hxys-item'>
                  <img src={img1} alt="" />
                  <div className='item-content'>
                    <div className='item-title'>
                      完善的派送体系
                    </div>
                    <div className='item-desc'>
                      3300家门店，50000多个窗口，全网160000名员工，是客户强而有力的后盾
                    </div>
                  </div>
                </div>
                <div className='hxys-item'>
                  <img src={img6} alt="" />
                  <div className='item-content'>
                    <div className='item-title'>
                      完备的售后服务
                    </div>
                    <div className='item-desc'>
                      配备专门客服，进行7*12小时客服服务
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='hxys-img'>
              <img src={banner3} alt="" />
            </div>
          </div>
        </div>
      </div >
    )
  }
}
