import React, { Component } from 'react'
import image2 from "../assets/images/imgs/image2.jpg";
import right from "../assets/images/icons/right.png";
import '../styles/register.scss'
import logo from '../assets/images/imgs/logo.png'
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import icons1 from "../assets/images/icons/icons1.png";
import icons2 from "../assets/images/icons/icons2.png";
import icons3 from "../assets/images/icons/icons3.png";


export default class Register extends Component {
  state: { active: number }
  constructor(props: any) {
    super(props)
    this.state = {
      active: 1
    }
  }
  render() {
    return (
      <div className='register' style={{ backgroundImage: `url(${image2})` }}>
        <div className='header'>
          <img className='logo' src={logo} alt="" />
          <div className='header-main'>
            <UserOutlined style={{ position: 'relative', top: '-5px' }} />
            <span onClick={() => {
              window.location.href = 'https://juc.suyoda.cn/vue/#/login'
            }} style={{ cursor: 'pointer' }} className='mr-20 ml-1'>已有账号</span>
            <span style={{ position: 'relative', top: '-1px' }} className='mr-20'>|</span>
            <Link to={{ pathname: '/' }} style={{ cursor: 'pointer', color: '#fff' }} className='ml-1' replace> 返回首页</Link>
          </div>
        </div>
        <div className='main'>
          <div className='title'>
            注册账号
          </div>
          <div className='line'></div>
          <div className='desc'>选择注册类型，每个账号仅能注册一种类型</div>
          <div className='moudel'>
            <div onClick={() => {
              this.setState({
                active: 1
              })
            }} className={this.state.active == 1 ? 'moidel-li red active' : 'moidel-li red'}>
              {this.state.active == 1 ? <img src={right} className='chooseimg' alt="" /> : ''}
              <img src={icons1} className='model-logo' alt="" />
              <div className='model-name'>加 盟 商</div>
            </div>
            <div onClick={() => {
              this.setState({
                active: 2
              })
            }} className={this.state.active == 2 ? 'moidel-li yellow active' : 'moidel-li yellow'}>
              {this.state.active == 2 ? <img src={right} className='chooseimg' alt="" /> : ''}
              <img src={icons2} className='model-logo' alt="" />
              <div className='model-name'>个 人 商 户</div>
            </div>
            <div onClick={() => {
              this.setState({
                active: 3
              })
            }} className={this.state.active == 3 ? 'moidel-li green active' : 'moidel-li green'}>
              {this.state.active == 3 ? <img src={right} className='chooseimg' alt="" /> : ''}
              <img src={icons3} className='model-logo' alt="" />
              <div className='model-name'>企 业 商 户</div>
            </div>
          </div>
          <div className='submit'>注 册</div>
        </div>
        <div className='footer-bottom'>
          <a style={{ color: '#fff' }} target='_blank' href='http://www.beian.miit.gov.cn/'>
            鲁ICP备17015604号-5
          </a>
          <div>
            © 2018 版权所有 青岛润亨丰国际贸易有限公司,All Right Reserved
          </div>
        </div>
      </div>
    )
  }
}
