import React, { Component } from 'react'
import '../styles/expressimport.scss'
import shuzhi from "../assets/images/imgs/shuzhi.png";
import map from "../assets/images/imgs/map.png";
import liucheng from "../assets/images/imgs/liucheng.png";
import PK from "../assets/images/imgs/PK.png";

export default class Transport extends Component {
  render() {
    return (
      <div className='transport'>
        <div className='model'>
          <div className='title'>
            核心服务
          </div>
          <div className='desc' style={{ margin: '0' }}>
            速邮达为客户提供从海外到中国境内消费者终端的一站式、高品质清关服务，采用快件形式，按照个人物品类别、海关征税表，多口岸操作，将清关时效提升到24小时！
          </div>
          <div className='shuzhi'>
            <div className='shuzhi-left' style={{ textAlign: "right", marginRight: "20px" }}>
              <div className='shuzhi-list' style={{ marginTop: "88px" }}>
                <div className='shuizhi-name'>航空运输</div>
                <div className='shuzhi-desc'>通过山东航空、天津航空等各大航空公司开展战略合作有力的支持了客户对时效快件的寄递需求。</div>
              </div>
              <div className='shuzhi-list' style={{ marginTop: "101px" }}>
                <div className='shuizhi-name'>口岸清关
                </div>
                <div className='shuzhi-desc'>目前，我们拥有青岛、济南、威海、烟台等多口岸的清关服务。</div>
              </div>
            </div>
            <img src={shuzhi} alt="" />
            <div className='shuzhi-left' style={{ textAlign: "left", marginLeft: "15px" }}>
              <div className='shuzhi-list' style={{ marginTop: "-10px" }}>
                <div className='shuizhi-name'>国际快递</div>
                <div className='shuzhi-desc'>直邮模式：客户—电商网站—海外供货商——海外仓—空运—海关商检—监管库—客户收货</div>
              </div>
              <div className='shuzhi-list' style={{ marginTop: "123px" }}>
                <div className='shuizhi-name'>海外清仓
                </div>
                <div className='shuzhi-desc'>保税模式：客户—电商网站—海外供货商——海外仓—空运—保税仓—海关商检—监管库—客户收货</div>
              </div>
              <div className='shuzhi-list' style={{ marginTop: "102px" }}>
                <div className='shuizhi-name'>供应链
                </div>
                <div className='shuzhi-desc'>高效运营—安全保证—全程透明—全球定位—订单管理—全程管理</div>
              </div>
            </div>
          </div>
        </div>
        <div className='map'>
          <div className='map-title'>
            转运分布
          </div>
          <div className='map-desc'>
            运转作为跨国货运的重要渠道，不但可以为用户提供良好的使用体验，还可以满足不同电商和运转商的业务需求。
          </div>
          <img src={map} alt="" />
        </div>
        <div className='model'>
          <div className='title'>
            服务流程
          </div>
          <div className='desc' style={{ textAlign: "center", margin: '0' }}>
            合理化的服务流程，更加方便快捷，服务用户。
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <img src={liucheng} alt="" />
          </div>
        </div>
        <div className='msys'>
          <div className='title'>
            模式优势
          </div>
          <div className='msys-content'>
            <div className='msys-model'>
              <div className='msys-title'>
                速邮达模式
              </div>
              <div className='msys-list'>
                <div className='msys-item'>
                  <div className='msys-num'>
                    1
                  </div>
                  <div className='msys-desc'>
                    海关监管，专人合法操作，税费税单明细清晰
                  </div>
                </div>
                <div className='msys-item'>
                  <div className='msys-num'>
                    2
                  </div>
                  <div className='msys-desc'>
                    申报模式简单快捷，免征品类丰富
                  </div>
                </div>
                <div className='msys-item'>
                  <div className='msys-num'>
                    3
                  </div>
                  <div className='msys-desc'>
                    24小时快捷通关
                  </div>
                </div>
              </div>
            </div>
            <img src={PK} alt="" />
            <div className='msys-model'>
              <div className='msys-title'>
                其他模式
              </div>
              <div className='msys-list'>
                <div className='msys-item grey'>
                  <div className='msys-num' style={{ color: 'rgba(144, 144, 144, 1)' }}>
                    1
                  </div>
                  <div className='msys-desc greycolor'>
                    无专人操作，税费税单明细不清晰
                  </div>
                </div>
                <div className='msys-item grey'>
                  <div className='msys-num' style={{ color: 'rgba(144, 144, 144, 1)' }}>
                    2
                  </div>
                  <div className='msys-desc greycolor'>
                    无固定申报模式，过程反复繁琐，可邮寄种类较少
                  </div>
                </div>
                <div className='msys-item grey'>
                  <div className='msys-num' style={{ color: 'rgba(144, 144, 144, 1)' }}>
                    3
                  </div>
                  <div className='msys-desc greycolor'>
                    清关时间一般在48小时以上
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
