import { ClusterOutlined, SearchOutlined, GlobalOutlined, PartitionOutlined, FileDoneOutlined, CrownOutlined } from '@ant-design/icons';

export const advantages: any = [{
  title: '完善的全球货运系统',
  content: '自行开发了GOS全球货运系统。把客户订单、物流监控，海关申报系统联系在一起。提供完整的系统化解决方案，与电商平台完美对接。',
  icons: < ClusterOutlined />
}, {
  title: '完善的干线运输体系',
  content: '与山东航空、天津航空、仁川航空等13家航空公司及威东航运、韩国大仁轮渡、胶东国际海运等6家船运公司签署战略合作，为客户提供完善的干线运输服务',
  icons: <SearchOutlined />
}, {
  title: '海外仓储服务',
  content: '提供一站式仓储配送服务，帮助中国客户实现海外仓储集包服务，降低物流运营成本，进行实时库存管理与监测，缩短发货时间，提高顾客满意度。',
  icons: <GlobalOutlined />
}, {
  title: '丰富的口岸清关资源',
  content: '目前，我们提供青岛、济南、威海、烟台、天津、昆明、武汉、哈尔滨、广州口岸的清关服务。',
  icons: <CrownOutlined />
}, {
  title: '强大的快递派送体系',
  content: '韵达在全国拥有3300家门店，50000多个窗口，全网160000名员工，完美解决最后一公里。',
  icons: <PartitionOutlined />
}, {
  title: '完善的售后服务体系',
  content: '配备专门客服，进行7*12小时客服服务。货物出现问题，先行赔付。',
  icons: <FileDoneOutlined />
}]