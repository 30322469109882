import React, { Component, useState } from 'react'
import img_search from '../assets/images/icons/search.jpg'
import { Input, Empty, message, Timeline, Tabs, Spin } from 'antd';
const { TextArea } = Input;
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { Button } from 'antd';
import img_stone from '../assets/images/icons/stone.jpg'
import img_band from '../assets/images/icons/band.jpg'
import img_conect from '../assets/images/icons/conect.jpg'
import img_bag from '../assets/images/icons/bag.jpg'
import img_total from '../assets/images/icons/total.jpg'
import { advantages } from "./advantages";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Image } from 'antd';
import image1 from "../assets/images/imgs/image1.jpg";
import image2 from "../assets/images/imgs/image2.jpg";
import image3 from "../assets/images/imgs/image3.png";
import Search from 'antd/lib/transfer/search';
import axios from 'axios'
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  CaretUpFilled,
  CaretDownFilled
} from '@ant-design/icons';
const { TabPane } = Tabs;
export default class Home extends Component {
  state: { size: SizeType, imgarr: any[], advantagess: any[], obtArr: any[], times: boolean, back: number, ordercode: string, showmark: boolean, empty: boolean, timeList: [], reverse: boolean, activeKey: number, loading: boolean, showsearch: boolean }
  constructor(props: any) {
    super(props)
    this.state = {
      size: 'large',
      imgarr: [{ name: img_stone, title: '我要寄件' }, { name: img_band, title: '运费时效' }, { name: img_conect, title: '范围查询' }, { name: img_bag, title: '服务专业' }, { name: img_total, title: '客服咨询' }],
      advantagess: advantages,
      times: true,
      obtArr: [{
        title: '进口服务',
        content: '专注跨境直邮清关服务多年。不断完善的服务体系，为您打造低成本，高质量，清关速度高效的一站式通关服务！',
        img: image1
      }, {
        title: '出口服务',
        content: '通过整合世界干线运输资源、出口报关清关资源以及中国大陆全域上门收件服务，迅速成长为跨境物流领域的领先品牌。',
        img: image2
      }, {
        title: '供应链+仓储服务',
        content: '供应链的轻巧程度决定了产品线广度及深度。专业的电商仓储管理，让你从复杂的仓储，分拣，打包过程中解放出来，体验更加优质的服务！',
        img: image3
      },],
      back: 0,
      ordercode: '',
      showmark: false,
      empty: true,
      timeList: [],
      reverse: false,
      activeKey: 0,
      loading: false,
      showsearch: false
    }
  }
  componentDidMount() {
    window.addEventListener('resize', () => { //监听浏览器窗口大小改变
      //浏览器变化执行动作
    })
  }
  handleCnhange(e: any) {
    this.setState({
      ordercode: e.target.value
    })
  }
  onChange(key: number) {
    this.setState({
      activeKey: key
    })
  };
  Search() {
    // 方式二:
    this.setState({
      loading: true
    })
    const data = new FormData()
    data.append('code_content', this.state.ordercode)
    axios({
      method: 'post',
      url: 'https://www.suyoda.cn/api/get_route/query',
      // url: 'api/get_route/query',

      data,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', 'Accept': 'application/json, text/javascript, */*; q=0.01' }
    }).then(res => {
      this.setState({
        showmark: true,
        loading: false,
        showsearch: false
      })
      if (res.data.data.length) {
        this.setState({
          timeList: res.data.data,
          empty: false,
          activeKey: 0
        })
      } else {
        this.setState({
          empty: true
        })
      }
    }).catch(err => {
      console.log(err);
      this.setState({
        loading: false
      })
      message.error('查询失败', 2);
    })

    // localStorage.setItem('ordercode', this.state.ordercode)
    // console.log(localStorage.getItem('ordercode'));
    // window.location.href = 'https://www.suyoda.cn/Orderdetail'
  }
  render() {
    return (
      <div>
        {this.state.showmark ? <div onClick={() => {
          this.setState({
            showmark: false
          })
        }} className='masker'>
        </div> : ''}
        <div className='home'>
          {this.state.showmark ? <div className='searchDetial'>
            {this.state.empty ? <Empty style={{ marginTop: '50px' }} description="查无结果，请检查单号是否有误" />
              : <div className='search-content'>
                <Tabs onChange={(key: any) => {
                  this.onChange(key)
                }} defaultActiveKey="0" >
                  <div className='search-button' onClick={() => {
                    this.setState({
                      reverse: !this.state.reverse
                    })
                  }}>
                    时间{this.state.reverse ? <CaretUpFilled style={{ position: 'relative', top: '-4px' }} /> : <CaretDownFilled style={{ position: 'relative', top: '-4px' }} />}
                  </div>
                  {this.state.timeList.map((item: any, index) => (
                    <TabPane tab={item.code} key={index}>
                    </TabPane>
                  ))}
                </Tabs>
                {this.state.timeList.map((item: any, index) => (
                  <div key={`a${index}`} className='search-list'>
                    {index == this.state.activeKey ?
                      <div className='item-content'>
                        <Timeline reverse={this.state.reverse}>
                          {item.steps.length ? '' : <Timeline.Item color="red">查无结果</Timeline.Item>}
                          {item.steps.map((i: any, n: number) => (
                            <Timeline.Item key={n} color={n == 0 ? "green" : "gray"} >
                              <p style={n == 0 ? { color: '#0066ff' } : {}}>{i.time}</p>
                              <p style={n == 0 ? { color: '#0066ff' } : {}}>{i.address}</p>
                              {i.img ? <a href={i.img} target='_blank'>点击查看签收图片</a> : ''}
                            </Timeline.Item>
                          ))}
                        </Timeline>
                      </div> : ''}
                  </div>
                ))}
              </div>}
          </div> : ''}
          <div className='search'>
            {this.state.showsearch ? <div className="animate animate__animated animate__fadeInUp  animate__faster search_input">
              <div className='title'>
                运单号查询
                <CloseCircleOutlined onClick={() => {
                  this.setState({
                    showsearch: false
                  })
                }} style={{ float: 'right', position: 'relative', top: '8px', cursor: 'pointer', color: '#1890ff' }} />
              </div>
              <TextArea onChange={(e) => {
                this.handleCnhange(e)
              }} autoFocus autoSize={{ minRows: 10, maxRows: 10 }} placeholder="请输入运单号进行查询,最多可输入20个运单号，每行一个" />
              <Button onClick={() => {
                this.Search()
              }} style={{ width: '100%', marginTop: '10px' }} type="primary" size='large'>
                查单
              </Button>
            </div> : ''}


            <div className='search-input' onClick={() => {
              this.setState({
                showsearch: true
              })
            }}>
              <img src={img_search} alt="" />
              <span className='line'></span>
              <span style={{ fontSize: '18px', color: '#fff', letterSpacing: '2px', marginLeft: '20px' }}>请输入运单号进行查询,最多可输入20个运单号，每行一个</span>
              {/* <TextArea onChange={(e) => {
                this.handleCnhange(e)
              }} disabled value={this.state.ordercode} bordered={false} autoSize={{ minRows: 1, maxRows: 1 }} className='reactinput' style={{ backgroundColor: 'rgba(0, 0, 0, 0)', border: 'none', outline: 'none' }} placeholder="请输入运单号进行查询,最多可输入20个运单号，每行一个" /> */}
            </div>
            {/* <Button onClick={() => {
              this.Search()
            }} style={{ height: '60px', width: '150px', marginLeft: '10px' }} type="primary" shape="round" size={(this.state as any).size} loading={this.state.loading}>
              立 即 查 询
            </Button> */}
          </div>
          <div className='icons'>
            {this.state.imgarr.map((item, index) => (
              <div key={index}>
                <img src={item.name} alt="" />
                <div>{item.title}</div>
              </div>
            )
            )}
          </div>
        </div>
        <div className='advantages'>
          <div className='title'>
            服务优势
          </div>
          <div className='list-ul'>
            {this.state.advantagess.map((advant, index) => (
              <div key={index} className='list-li'>
                <div className='li-title'>
                  {advant.title}
                </div>
                <div className='li-content'>
                  {advant.content}
                </div>
                <div className='li-icons'>
                  {advant.icons}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='obtainemployment' style={{ backgroundImage: `url(${this.state.obtArr[this.state.back].img})` }}>
          <div className='obt-title'>
            业务方向
          </div>
          <Swiper
            modules={[Pagination]}
            spaceBetween={20}
            slidesPerView={3}
            centeredSlides={true}
            onSlideChange={(e) => e.activeIndex == 3 || e.activeIndex == 6 ? this.setState({
              back: 0
            }) : e.activeIndex == 4 ? this.setState({
              back: 1
            }) : this.setState({
              back: 2
            })}
            // onSwiper={(swiper) => console.log(swiper)}
            pagination={{ clickable: true }}
            loop={true}
            className='swiper-container'
          >
            {this.state.obtArr.map((item, index) => (
              <SwiperSlide key={index} className='swiper-slide'>
                <img className='img' src={item.img} alt="" />
                <div className='swiper-content'>
                  <div className='content-title'>
                    {item.title}
                  </div>
                  <div className='content-main'>
                    {item.content}
                  </div>
                  {/* <div className='more'>
                    了解更多
                  </div> */}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* <div className='news'>

        </div> */}

      </ div >
    )
  }
}
